@import '../../assets/styles/variables';

.planAdiet-wrapper {
    // margin-top: 94px;
    margin-top: 5.875em;

    .planAdiet-content-wrapper {
        background-color: $greenLight;
        min-height: 100vh;

        .planAdiet-content {
            h5 {
                color: $red2;
                font-family: $global-font3;
                padding-top: 43px;
                font-size: $fontsize3;
                line-height: 1.625em;
            }

            h2 {
                color: $black;
                font-size: $fontsize7;
                line-height: 64px;
                font-family: $global-font3;
            }

            p {
                color: $black;
                font-size: $fontsize2;
                line-height: 50px;
            }

            hr {
                width: 90%;
                margin-left: 50px;
                border: 1px dashed #000000;
            }

            ul {
                padding-left: 0;
                list-style-type: none;

                li {
                    .slNo {
                        background-color: $red2;
                        border-radius: 50%;
                        width: 36px;
                        height: 36px;
                        padding-left: 13px;
                        padding-top: 5px;
                        color: $white;
                        margin-right: 20px;
                    }

                    h6 {
                        padding-top: 7px;
                        padding-right: 10px;
                        color: $black;
                        // font-size: 16px;
                        font-size: $fontsize2;
                        line-height: 25px;
                        font-family: $global-font3;
                    }

                    button {
                        font-family: $global-font3;
                        // font-size: 20px;
                        font-size: $fontsize3;
                        // line-height: 26px;
                        line-height: 1.625em;
                    }
                }

                .gender-li {
                    display: flex;

                    button {
                        // width: 115px;
                        height: 37px;
                        margin-right: 10px;
                    }
                }

                .needFordiet-li {
                    button {
                        margin-right: 23px;
                        margin-bottom: 23px;
                        height: 37px;
                    }

                    .needFordiet {
                        display: flex;
                        margin-top: 23px;
                        margin-bottom: 23px;
                    }

                    .needFordiet-btnGroup {
                        padding-left: 50px;

                        button {
                            height: auto;
                            min-height: 37px;
                        }
                    }
                }
            }

            .calculateBmi-li {
                padding-top: 90px;

                .heightDflex {
                    display: flex;

                    .form-text {
                        // font-size: 16px;
                        font-size: $fontsize2;
                        line-height: 46px !important;
                        color: $black2 !important;
                        font-family: $global-font3;
                    }

                    .form-control:focus {
                        box-shadow: none !important;
                    }

                    .form-control {
                        width: 48% !important;
                        text-align: center;
                        color: $black2 !important;
                        font-family: $global-font3;
                        // font-size: 20px;
                        font-size: $fontsize3;
                        // line-height: 26px;
                        line-height: 1.625em;
                        border-radius: 19px;
                        height: 37px;
                    }

                    .calBmi-btn {
                        background-color: $red2 !important;
                        border-color: $red2 !important;
                        color: $white !important;
                        border-radius: 19px !important;
                        padding: 0 25px;
                        border: none;
                        height: 37px;
                        margin-top: 44px;
                    }
                }

                hr {
                    width: 100%;
                    margin-left: 0px;
                    margin-top: 30px;
                }

            }

            .btn-disabled {
                button {
                    // line-height: 26px;
                    line-height: 1.625em;
                    background-color: $grey7;
                    color: $grey6;
                    border-radius: 30px;
                    padding: 14px 45px;
                    // font-size: 20px;
                    font-size: $fontsize3;
                    font-family: $global-font3;
                    margin-top: 8em;
                    border: none;
                }
            }

            .calcDflex {
                display: flex;
                justify-content: space-between;
                margin-top: 37px;

                h6 {
                    color: $black;
                    // font-size: 16px;
                    font-size: $fontsize2;
                    line-height: 25px;
                    font-family: $global-font3;
                }

                a {
                    color: $black;
                    // font-size: 16px;
                    font-size: $fontsize2;
                    line-height: 25px;
                    font-family: $global-font3;
                }
            }

            .proceed-btn {
                button {
                    // line-height: 26px;
                    line-height: 1.625em;
                    background-color: $red2;
                    color: $grey6;
                    border-radius: 30px;
                    padding: 14px 45px;
                    // font-size: 20px;
                    font-size: $fontsize3;
                    font-family: $global-font3;
                    margin-top: 38px;
                    border: none;
                }
            }

            .calcBmi-para {
                padding-top: 42px;

                p {
                    font-size: $fontsize2;
                    line-height: 1.625em !important;
                    color: $black2 !important;
                    font-family: $global-font3;

                    span.weight-range{
                        color: $red2;
                    }
                }
            }

            .calcBmi-slider {
                display: flex;
                justify-content: left;

                h4 {
                    // font-size: 30px;
                    font-size: $fontsize6;
                    line-height: 38px;
                    font-family: $global-font3;
                    color: $green;
                    padding: 0 20px;
                }

                p {
                    // font-size: 50px;
                    font-size: $fontsize7;
                    line-height: 64px;
                    color: $red2;
                    font-family: $global-font3;
                    text-align: center;

                }

                a {
                    color: $black2;
                    // font-size: 16px;
                    font-size: $fontsize2;
                    line-height: 40px;
                    font-family: $global-font3;
                    margin-left: 73px;
                    margin-right: auto;
                }
            }

            .activityLevel {
                padding-top: 90px;
                padding-bottom: 40px;

                .activityLevel-li {
                    padding-bottom: 40px;
                }

                button {
                    width: 100%;
                    min-height: 37px;
                    height: auto;
                }

                p {
                    // font-size: 14px;
                    font-size: $fontsize1;
                    line-height: 25px;
                    font-family: $global-font3;
                    color: $black;
                }

                .activityLevel-row {
                    min-height: 65px;

                    button {
                        // font-size: 20px;
                        font-size: $fontsize3;
                        // line-height: 26px;
                        line-height: 1.625em;
                        color: $black2;
                        font-family: $global-font3;
                    }
                }
            }

            .back-button-wraper {
                display: inline-block;
                width: 20px;
                position: relative;
                top: 10px;
            }

            .back-button-wraper.page-2{
                top: 60px;
            }
        }

        .planAdiet-img {
            img {
                padding-top: 77px;
            }
        }
    }
}

// -------------------------modal style--------------------------//

.planDietOptions-wrapper {
    display: flex;
    justify-content: center;
    background: #fff;
    padding: 52px 0;

    .dietPlanOption {
        margin-left: 18px;
        margin-right: 18px;
    }
}

.bottom-popup {
    position: fixed;
    bottom: -100%;
    left: 0;
    right: 0;
    transition: bottom 0.3s ease-in-out;

    .popup-content {
        background-color: #fff;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
        padding: 20px;
        min-height: 35vh;

        button {
            background-color: #333;
            color: #fff;
            border: none;
            padding: 10px 20px;
            cursor: pointer;
        }
    }
}

.bottom-popup.open {
    bottom: 0;
}

@-moz-document url-prefix() {
    @media only screen and (min-width: 993px) and (max-width: 1200px) {
        .planAdiet-content-wrapper {
            .planAdiet-content {
                .calculateBmi-li {
                    .heightDflex {
                        .calBmi-btn {
                            // width: 34% !important;
                        }
                    }
                }
            }
        }
    }
}



// ---------------------media query------------------------//

@media only screen and (max-width: 768px) {
    .planAdiet-img {
        img {
            width: 355px;
            margin-left: auto;
            margin-right: auto;
            display: block;
            padding-top: 22px !important;
        }
    }

    .planAdiet-wrapper {
        margin-top: 68px;
    }

    .planAdiet-wrapper {
        .planAdiet-content-wrapper {
            .planAdiet-content {
                hr {
                    width: 100%;
                    margin-left: 0px;
                    border: 1px dashed #000000;
                }

                ul {
                    .needFordiet-li {
                        .needFordiet-btnGroup {
                            padding-left: 25px;
                        }
                    }

                    li {
                        .slNo {
                            width: 38px;
                            margin-right: 9px;
                        }
                    }
                }

                .calculateBmi-li {
                    // padding-top: 26px;
                    padding-top: 1.625em;

                    .heightDflex {
                        display: unset;

                        .calBmi-btn {
                            margin-top: 30px;
                        }
                    }
                }

                .calcBmi-slider {
                    display: unset;

                    a {
                        margin-left: 0;
                    }
                }

                .calcDflex {
                    display: unset;
                }

                .btn-disabled {
                    button {
                        margin-top: 4em;
                        margin-bottom: 10px;
                    }
                }

                .activityLevel {

                    padding-top: 32px;
                    padding-bottom: 0px;

                    button {
                        margin-bottom: 10px;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 410px) {
    .planAdiet-wrapper {
        margin-top: 68px;

        .planAdiet-content-wrapper {
            .planAdiet-content {
                ul {
                    .needFordiet-li {
                        .needFordiet-btnGroup {
                            padding-left: 0;
                        }

                        button {
                            margin-right: 0;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
    .planAdiet-img {
        img {
            width: 300px;
        }
    }

    .planAdiet-wrapper {
        margin-top: 68px;

        .planAdiet-content-wrapper {
            .planAdiet-content {
                .calculateBmi-li {
                    padding-top: 50px;

                    .heightDflex {
                        display: unset;
                    }
                }

                .calcBmi-slider {
                    display: unset;

                    a {
                        margin-left: 0;
                    }
                }

                .calcDflex {
                    display: unset;
                }

                .btn-disabled {
                    button {
                        margin-top: 3em;
                        margin-bottom: 10px;
                    }
                }
            }
        }
    }
}

@media only screen and (min-width: 993px) and (max-width: 1200px) {
    .planAdiet-img {
        img {
            width: 300px;
        }
    }

    .planAdiet-wrapper {

        .planAdiet-content-wrapper {
            .planAdiet-content {
                .calculateBmi-li {
                    .heightDflex {
                        .calBmi-btn {
                            padding: 0 16px !important;
                            width: 34% !important;
                        }
                    }
                }

                .calcDflex {
                    display: unset;
                }

                .btn-disabled {
                    button {
                        margin-top: 4em;
                        margin-bottom: 10px;
                    }
                }
            }
        }
    }
}

@media only screen and (min-width: 993px) and (max-width: 1399px) {
    .calculateBmi-li {
        .heightDflex {
            .calBmi-btn {
                width: 34%;
            }
        }
    }
}

@media only screen and (min-width: 1201px) and (max-width: 1400px) {
    .planAdiet-img {
        img {
            width: 400px !important;
            padding-left: 4em;
        }
    }

    .planAdiet-wrapper {
        .planAdiet-content-wrapper {
            .planAdiet-content {
                ul {
                    .needFordiet-li {
                        .needFordiet-btnGroup {
                            button {
                                height: auto;
                                min-height: 32px;
                            }
                        }
                    }
                }
            }
        }
    }
}



@media only screen and (min-width: 1201px) and (max-width: 1550px) {
    .planAdiet-img {
        img {
            width: 465px;
        }
    }

    .planAdiet-content-wrapper {
        .planAdiet-content {
            .calculateBmi-li {
                .heightDflex {
                    .calBmi-btn {
                        padding: 0 16px !important;
                    }
                }
            }
        }
    }
}

@media only screen and (min-width: 1500px) and (max-width: 1550px) {

    .nav-bg .container-fluid {
        padding: 0 95px;
    }
}

@media screen and (min-height: 700px) {
    .planDietOptions-wrapper {
        padding: 2px 0;
    }

    .dietPlanOption-wrapper {
        .dietPlanOption {
            width: 240px;
            padding: 14px 10px;

            h5 {
                padding-bottom: 0px !important;
            }

            p {
                line-height: 17px;
                padding-top: 0px;
                padding-bottom: 3px;
            }
        }
    }

    .planAdiet-wrapper {
        .planAdiet-content-wrapper {
            .planAdiet-content {
                .activityLevel {
                    padding-top: 28px;
                }
            }
        }
    }
}