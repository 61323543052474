.diet {
    input[type=range] {
        -webkit-appearance: none;
        margin: 10px 0;
        width: 100%;
        background: transparent;
    }
    
    input[type=range]:focus {
        outline: none;
    }
    
    input[type=range]::-webkit-slider-runnable-track {
        width: 100%;
        height: 7px;
        cursor: pointer;
        background: transparent linear-gradient(90deg, #6C9D11 0%, #F56F5D 100%) 0% 0% no-repeat padding-box;
        border-radius: 0px;
        border: 0px solid #000101;
    }
    
    input[type=range].active::-webkit-slider-thumb {
        border: 0px solid #000000;
        height: 17px;
        width: 2px;
        border-radius: 7px;
        background: #F56F5D;
        cursor: pointer;
        -webkit-appearance: none;
        margin-top: -5px;
    }

    input[type=range].inactive::-webkit-slider-thumb {
        display: none;
    }
    
    input[type=range]:focus::-webkit-slider-runnable-track {
        background: transparent linear-gradient(90deg, #6C9D11 0%, #F56F5D 100%) 0% 0% no-repeat padding-box;
    }
    
    input[type=range]::-moz-range-track {
        // width: 100%;
        height: 7px !important;
        cursor: pointer;
        animate: 0.2s;
        background: transparent linear-gradient(90deg, #6C9D11 0%, #F56F5D 100%) 0% 0% no-repeat padding-box;
        border-radius: 0px !important;
        border: 0px solid #000101;
    }
    
    input[type=range].active::-moz-range-thumb {
        border: 0px solid #000000;
        height: 17px !important;
        width: 2px !important;
        border-radius: 0px;
        background: #F56F5D;
        cursor: pointer;
    }

    input[type=range].inactive::-moz-range-thumb {
        display: none !important;
        width: 0;
        height: 0;
        opacity: 0;
        cursor: none;
    }
    
    input[type=range]::-ms-track {
        width: 100%;
        height: 7px !important;
        cursor: pointer;
        animate: 0.2s;
        background: transparent;
        border-color: transparent;
        border-width: 39px 0;
        color: transparent;
    }
    
    input[type=range]::-ms-fill-lower {
        background: transparent linear-gradient(90deg, #6C9D11 0%, #F56F5D 100%) 0% 0% no-repeat padding-box;
        border: 0px solid #000101;
        border-radius: 50px;
    }
    
    input[type=range]::-ms-fill-upper {
        background: transparent linear-gradient(90deg, #6C9D11 0%, #F56F5D 100%) 0% 0% no-repeat padding-box;
        border: 0px solid #000101;
        border-radius: 50px;
    }
    
    input[type=range].active::-ms-thumb {
        border: 0px solid #000000;
        height: 17px;
        width: 2px;
        border-radius: 0px;
        background: #F56F5D;
        cursor: pointer;
    }

    input[type=range].inactive::-ms-thumb {
        display: none !important;
    }
    
    input[type=range]:focus::-ms-fill-lower {
        background: transparent linear-gradient(90deg, #6C9D11 0%, #F56F5D 100%) 0% 0% no-repeat padding-box;
    }
    
    input[type=range]:focus::-ms-fill-upper {
        background: transparent linear-gradient(90deg, #6C9D11 0%, #F56F5D 100%) 0% 0% no-repeat padding-box;
    }
}