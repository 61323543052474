@import '../../../assets/styles/variables';

.title-primary {
  background-color: $green4;
  margin-top: 46px;

  .titleContent {
    margin-left: auto;
    margin-right: auto;

    h3 {
      text-align: center;
      padding: 42px 0 55px 0;
      margin-bottom: 0;
      font-size: $fontsize8;
      line-height: 71px;
      color: $black;
      font-family: $global-font3;
    }

    .titleBtn {
      display: flex;
      justify-content: center;
      padding-bottom: 90px;

      .btn-red {
        padding: 15px 65px;
        font-size: 20px;
        line-height: 26px;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .title-primary {
    h3 {
      padding: 33px 0 33px 0;
      font-size: 2em;
      line-height: 42px;
    }
  }
}

@media only screen and (min-width: 769px) and (max-width: 991px) {
  .title-primary {
    h3 {
      padding: 33px 0 33px 0;
      font-size: 3em;
      line-height: 58px;
    }
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .title-primary {
    h3 {
      padding: 38px 0 38px 0;
      font-size: 3.2375em;
      line-height: 62px;
    }
  }
}