@import '../../../assets/styles/variables';

.nav-bg {
  background-color: $greenLight;
  height: 95px;
  border-bottom: 1px solid $grey2;

  .container-fluid {
    padding: 0 152px;
  }

  .navbar-nav {
    a {
      color: #000;
      font-weight: 600;
      font-size: 22px;
      font-family: $global-font;
    }

    a:hover {
      color: $green2;
    }

    a:active {
      color: $green2 !important;
    }

    
    a:focus {
      color: $green2 !important;
    }

    .diet-p {
      padding-left: 75px !important;
      padding-right: 65px !important;
    }

    .tray-p {
      padding-left: 65px !important;
      padding-right: 65px !important;
      position: relative;

      span {
        position: absolute;
        background: #E44300;
        border-radius: 50%;
        color: #FFFFFF;
        width: 15px;
        height: 16px;
        font-size: 12px;
        padding-left: 4px;
        top: 29px;
        right: 35%;
        padding-bottom: 1px;
      }

      img {
        padding-left: 40px;
      }
    }

    .nav-icon {
      padding-left: 56px !important;
    }
  }

  .navbar-brand {
    font-size: 30px !important;
    font-family: $logo-font;
    line-height: 44px;
    color: $red2;
    letter-spacing: 2.4px;

    span {
      color: $green;
    }
  }

  .navbar-brand:hover {
    color: $red2;
  }

  .navbar-brand:focus {
    color: $red2;
  }
}

hr {
  color: #B9B9B9;
}


@-moz-document url-prefix() {
  .nav-bg {
    .navbar-nav {
      a {
        color: $black !important;
        font-weight: inherit !important;
        font-size: 22px !important;
        font-family: $global-font3;
      }

      .tray-p {
        padding-left: 65px !important;
        padding-right: 65px !important;
        position: relative;

        span {
          position: absolute;
          background: #E44300;
          border-radius: 50%;
          color: #FFFFFF;
          width: 15px;
          height: 16px;
          font-size: 12px;
          padding-left: 4px;
          top: 29px;
          right: 35%;
          padding-bottom: 1px;

        }

        img {
          padding-left: 40px;
        }
      }

      .nav-icon {
        padding-left: 56px !important;
      }
    }
  }
}

// --------------media query-----------------//

@media only screen and (max-width: 410px) {
  .nav-bg {
    .navbar-nav {
      .tray-p {
        span {
          left: 25% !important;
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .nav-bg {
    height: 70px;

    .container-fluid {
      padding: 0;
    }

    .navbar-collapse {
      background-color: $greenLight;
      margin-top: 8px;
      text-align: left;
    }

    .navbar-nav {
      .diet-p {
        padding-left: 18px !important;
        padding-right: 0 !important;
      }

      .tray-p {
        padding-left: 18px !important;
        padding-right: 0 !important;
        position: relative;

        img {
          padding-left: 10px;
        }

        span {
          left: 23%;
        }
      }

      .nav-icon {
        padding-left: 18px !important;
      }

      a {
        padding-left: 18px !important;
      }
    }

    .navbar-brand {
      padding-left: 18px;
    }

    .navbar-toggler {
      margin-right: 18px;
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 992px) {

  .nav-bg {
    height: 70px;

    .container-fluid {
      padding: 0;
    }

    .navbar-collapse {
      background-color: $greenLight;
      margin-top: 8px;
      text-align: left;
    }

    .navbar-nav {
      .diet-p {
        padding-left: 18px !important;
        padding-right: 0 !important;
      }

      .tray-p {
        padding-left: 18px !important;
        padding-right: 0 !important;

        span {
          left: 15%;
        }
      }

      .nav-icon {
        padding-left: 18px !important;
      }

      a {
        padding-left: 18px !important;
      }
    }

    .navbar-brand {
      padding-left: 18px;
    }

    .navbar-toggler {
      margin-right: 18px;
    }

    .navbar-toggler:focus {
      box-shadow: none;
    }
  }
}

@media only screen and (min-width: 993px) and (max-width: 1200px) {

  .nav-bg {
    .container-fluid {
      padding: 0 28px;
    }

    .navbar-nav {
      .diet-p {
        padding-left: 30px !important;
        padding-right: 18px !important;
      }

      .tray-p {
        padding-left: 28px !important;
        padding-right: 28px !important;

        span {
          right: 33%;
        }

        img {
          padding-left: 24px;
        }
      }

      .nav-icon {
        padding-left: 28px !important;
      }
    }
  }
}

@media only screen and (min-width: 1201px) and (max-width: 1400px) {
  .nav-bg {
    .navbar-nav {
      .diet-p {
        padding-left: 32px !important;
        padding-right: 32px !important;
      }

      .tray-p {
        padding-left: 32px !important;
        padding-right: 44px !important;
      }

      .nav-icon {
        padding-left: 30px !important;
      }
    }
  }
}

@media only screen and (min-width: 1201px) and (max-width: 1370px) {
  .nav-bg {
    .container-fluid {
      padding: 0 62px;
    }
  }
}

@media only screen and (min-width: 1371px) and (max-width: 1500px) {
  .nav-bg {
    .container-fluid {
      padding: 0 62px;
    }
  }
}

@media only screen and (min-width: 1500px) and (max-width: 1550px) {

  .nav-bg .container-fluid {
    padding: 0 95px;
  }
}

@media only screen and (min-width: 1551px) and (max-width: 1750px) {
  .nav-bg .container-fluid {
    padding: 0 95px;
  }
}