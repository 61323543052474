@import '../../../assets/styles/variables';

.bannerBlock-wrapper {
    padding-top: 100px;
    padding-bottom: 100px;
    h6 {
        font-size: 28px;
        line-height: 35px;
        font-family: $global-font3;
        color: $red2;
        text-align: center;
    }

    h2 {
        font-size: 80px;
        line-height: 103px;
        font-family: $global-font3;
        color: $black;
        text-align: center;
        padding-bottom: 41px;
    }

    p {
        font-size: 28px;
        line-height: 50px;
        font-family: $global-font;
        text-align: center;

        span {
            color: $red2;
        }
    }

    .bannerBlock-btngroup {
        margin-top: 50px;
        display: flex;
        justify-content: center;

        .btn-red {
            padding: 15px 30px 15px 30px;
            margin-right: 20px;
            font-size: 20px;
            line-height: 26px;
        } 
        .btn-green {
            padding: 15px 30px 15px 30px;
            font-size: 20px;
            line-height: 26px;
        } 
    }
}