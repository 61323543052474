@import '../../../assets/styles/variables';

.footer-wrapper {
  background-color: #F2FCF8;

  .footer-content {
    padding-top: 4em;
    padding-bottom: 5em;

  }

  hr {
    border: 1px solid #BABABA;
    margin: 0;
  }

  h5 {
    color: $black;
    // font-size: 20px;
    font-size: $fontsize3;
    // line-height: 26px;
    line-height: 1.625em;
    font-family: $global-font3;
  }

  p {
    color: $black;
    // line-height: 26px;
    line-height: 1.625em;
    font-family: $global-font;
    // font-size: 20px;
    font-size: $fontsize3;
  }

  a {
    color: $black;
    // line-height: 26px;
    line-height: 1.625em;
    text-decoration: none;
    font-family: $global-font;
    // font-size: 20px;
    font-size: $fontsize3;
  }

  ul {
    list-style-type: none;
    padding-left: 0;

    li {
      font-family: $global-font;
      // font-size: 20px;
      font-size: $fontsize3;
    }
  }

  .siteMap-wrapper {
    text-align: right;

    h5 {
      padding-bottom: 15px;
    }
  }

  .customerService-wrapper {
    text-align: right;

    h5 {
      padding-bottom: 15px;
    }
  }

  .copyright {
    text-align: center;
    // padding: 26px 0;
    padding: 1.625em 0;
    margin: 0;
  }

  .container-fluid {
    padding: 4em 6em 5em 6em;
  }

  .servicedBy-wrapper {
    h5 {
      padding-top: 10px;
      padding-bottom: 15px;
    }
  }
}


// --------------media query-------------//


@media only screen and (max-width: 768px) {
  .footer-wrapper {
    .container-fluid {
      padding: 1em 1em 1em 1em;
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 992px) {

  .footer-wrapper {
      .container-fluid {
          padding: 1em 1em 1em 1em;
      }
  }
}

@media only screen and (min-width: 993px) and (max-width: 1200px) {

  .footer-wrapper {
      .container-fluid {
          padding: 2em 2em 2em 2em;
      }
  }
}