@import '../../../assets/styles/variables';

.dietMeals-wrapper {
    padding-top: 77px;

    h2 {
        text-align: center;
        font-size: $fontsize11;
        line-height: 103px;
        font-family: $global-font3;
        color: $black;
        display: flex;
        width: 85%;
    }

    p {
        text-align: center;
        font-size: $fontsize10;
        line-height: 50px;
        color: $black;
        font-family: $global-font;
    }

    img {
        width: fit-content;
    }

    .dietMeals-btngroup {
        margin-top: 34px;
        margin-bottom: 72px;
        display: flex;
        justify-content: center;

        .btn-red {
            padding: 18px 25px 18px 25px;
            font-size: $fontsize3;
            line-height: 26px;
            margin-right: 18px;
        }


        .btn-green {
            padding: 18px 25px 18px 25px;
            font-size: $fontsize3;
            line-height: 26px;
        }
    }

    .dietMeals-img {
        width: 500px;
        margin-left: 3em;
        margin-top: 2em;
    }

    span.description_two {
        color: $red2;
    }

}

// ----media query

@media only screen and (max-width: 768px) {
    .dietMeals-wrapper {
        h2 {
            font-size: 3em;
            line-height: 84px;
            width: 90%;
        }

        .dietMeals-btngroup {
            margin-bottom: 20px;

            .btn-red {
                margin-left: auto;
                margin-right: auto;
                display: block;
                margin-bottom: 16px;
                padding: 6px 18px;
                font-size: 1em;
                line-height: 24px;
            }

            .btn-green {
                margin-left: auto;
                margin-right: auto;
                display: block;
                padding: 6px 18px;
                font-size: 1em;
                line-height: 24px;
            }
        }

        p {
            font-size: 1.2em;
            line-height: 33px;
        }

        .dietMeals-img {
            margin-left: 0;
        }
    }
}

@media only screen and (min-width: 769px) and (max-width: 991px) {
    .dietMeals-wrapper {
        h2 {
            font-size: 3em;
            line-height: 73px;
            width: 93%;
        }

        .dietMeals-btngroup {
            .btn-green {
                font-size: 1em;
                line-height: 20px;
                margin-bottom: 30px;
            }

            .btn-red {
                font-size: 1em;
                line-height: 20px;
                margin-bottom: 20px;
            }
        }
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .dietMeals-wrapper {
        h2 {
            font-size: 4em;
            line-height: 82px;
            width: 88%;
        }

        .dietMeals-btngroup {
            .btn-green {
                margin-bottom: 38px;
            }

            .btn-red {
                margin-bottom: 20px;
            }
        }
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .dietMeals-wrapper {
        h2 {
            font-size: 4em;
        }

        .dietMeals-btngroup {
            margin-bottom: 34px;

            .btn-red {
                margin-bottom: 20px;
            }
        }
    }
}