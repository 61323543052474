@import '../../../assets/styles/variables';

.breadcrumb-wrapper {
    height: 73px;
    padding-top: 25px;
    background-color: $greenLight;
    padding-left: 14em;
    border-bottom: 1px solid $grey8;
    margin-top: 6em;

    .breadcrumb-item::before {
        padding-top: 6px;
    }

    ol {
        margin: 0;

        li {
            a {
                color: $black2;
                text-decoration: none;
                font-family: $global-font3;
                font-size: $fontsize3;
                line-height: 1.625em;
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    .breadcrumb-wrapper {
        height: 63px;
        padding-top: 18px;
        padding-left: 1em;
        margin-top: 4em;
    }
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
    .breadcrumb-wrapper {
        padding-left: 4em;
        margin-top: 4em;
    }
}

@media only screen and (min-width: 993px) and (max-width: 1200px) {
    .breadcrumb-wrapper {
        padding-left: 2em;
    }
}

@media only screen and (min-width: 1201px) and (max-width: 1550px) {
    .breadcrumb-wrapper {
        padding-left: 5em;
    }
}

@media only screen and (min-width: 1500px) and (max-width: 1550px) {
    .breadcrumb-wrapper {
        padding-left: 8em;
    }
}

@media only screen and (min-width: 1551px) and (max-width: 1750px) {
    .breadcrumb-wrapper {
        padding-left: 8em;
    }
}