@import '../../assets/styles/variables';

.home-wrapper {
  background-color: $greenLight;

  .carousel-container {
    padding-top: 10em !important;
    padding: 0 9.5em !important;

    .carousel-content {
      margin-top: 8.5em;
    }

    .carousel-indicators {
      right: unset;
      margin-left: 0;
      bottom: 8%;
    }

    .carousel-indicators li {
      width: 15px;
      height: 15px;
      background-color: #6C9D11;
    }

    .carousel-indicators .active {
      background-color: #6C9D11;
    }

    .carousel-indicators [data-bs-target] {
      border-radius: 50%;
      height: 25px;
      width: 25px;
      margin-top: 2em;
      border: 1px solid $grey;
      margin-right: 10px;
    }

    h1 {
      font-family: $logo-font;
      color: $red2;
      font-size: $fontsize9;
      line-height: 146px;
      letter-spacing: 8px;

      span {
        color: $green;
      }
    }

    h3 {
      font-size: $fontsize8;
      line-height: 71px;
      color: $black;
      font-family: $global-font3;
    }

    p {
      font-size: $fontsize10;
      line-height: 50px;
      color: $black;
      font-family: $global-font3;
      padding-bottom: 91px;
      padding-top: 39px;
    }

    .carousel-img {
      img {
        width: 802px;
        height: 740px;
        margin-top: 5em;
        margin-left: 1em;

      }
    }
  }

  // .title-primary {
  //   background-color: $green4;
  //   margin-top: 46px;

  //   h3 {
  //     text-align: center;
  //     padding: 42px 0 55px 0;
  //     margin-bottom: 0;
  //     font-size: $fontsize8;
  //     line-height: 71px;
  //     color: $black;
  //     font-family: $global-font3;
  //   }
  // }

  .mealSubscription-wrapper {
    h3 {
      text-align: center;
      padding: 47px 0 27px 0;
      margin-bottom: 0;
      font-size: $fontsize8;
      line-height: 71px;
      color: $black;
      font-family: $global-font3;
    }

    .mealSubscription-img {
      background-image: url(../../assets/images/meal-subscription.png);
      opacity: 1;
      height: 467px;

      .nonVeg-wrapper {
        position: absolute;
        right: 12%;
        width: 574px;
        background: rgba($color: $black5, $alpha: 0.35);
        border: 1px solid $grey;
        height: 467px;

        h6 {
          color: $grey6 !important;
          font-size: $fontsize5;
          line-height: 31px;
          font-family: $global-font3;
          text-align: center;
          opacity: 1;
          padding-top: 71px;
        }

        p {
          font-size: $fontsize3;
          line-height: 26px;
          font-family: $global-font3;
          text-align: center;
          color: $grey6 !important;
          opacity: 1;
          padding-bottom: 10px;
          padding-left: 95px;
          padding-right: 95px;
          padding-top: 11px;
        }

        h5 {
          font-size: $fontsize5;
          line-height: 31px;
          color: $orange !important;
          font-family: $global-font3;
          text-align: center;
          opacity: 1;
          padding-bottom: 36px;
        }

        .btn-green {
          padding: 10px 20px;
          margin-left: auto;
          margin-right: auto;
          display: block;
        }
      }
    }
  }

  .finalCover-wrapper {
    margin-top: 125px;

    .finalCover-img {
      background-image: url(../../assets/images/final-cover.png);
      opacity: 1;
      height: 467px;

      .nonVeg-wrapper {
        position: absolute;
        right: 0;
        width: 574px;
        background: rgba($color: $black5, $alpha: 0.35);
        border: 1px solid $grey;
        height: 467px;

        h6 {
          color: $grey6;
          font-size: $fontsize5;
          line-height: 31px;
          font-family: $global-font3;
          text-align: center;
          opacity: 1;
          padding-top: 71px;
        }

        p {
          font-size: $fontsize3;
          line-height: 26px;
          font-family: $global-font3;
          text-align: center;
          color: $grey6;
          opacity: 1;
          padding-bottom: 10px;
          padding-left: 95px;
          padding-right: 95px;
          padding-top: 11px;
        }

        h5 {
          font-size: $fontsize5;
          line-height: 31px;
          color: $orange;
          font-family: $global-font3;
          text-align: center;
          opacity: 1;
          padding-bottom: 36px;
        }

        .btn-green {
          padding: 10px 20px;
          margin-left: auto;
          margin-right: auto;
          display: block;
        }
      }
    }
  }

  hr {
    margin: 0;
  }

  .chefCrafted-wrapper {
    h3 {
      text-align: center;
      padding: 105px 0 50px 0;
      margin-bottom: 0;
      font-size: $fontsize8;
      line-height: 71px;
      color: $black;
      font-family: $global-font3;
    }

    .chefCrafted-img {
      background-image: url(../../assets/images/img-grp.webp);
      height: 383px;
      margin-bottom: 125px;

      .chefCrafted-content {
        background-color: $green3;
        position: absolute;
        left: 13%;
        text-align: center;
        padding-top: 57px;
        width: 517px;
        height: 383px;

        h5 {
          font-size: $fontsize5;
          line-height: 31px;
          font-family: $global-font3;
          color: $black3;
        }

        .price {
          color: $orange !important;
          padding-top: 10px;
          padding-bottom: 8px;
        }

        p {
          font-size: $fontsize3;
          line-height: 26px;
          font-family: $global-font3;
          color: $black3;
          padding-top: 10px;
          padding-bottom: 15px;
        }

        .btn-white {
          background-color: $white2;
          padding: 13px 36px 13px 36px;
          font-size: $fontsize3;
          line-height: 26px;
          font-family: $global-font3;
          color: $black;
          border: none;
          border-radius: 25px;
        }
      }
    }
  }
}


// ----media query

@media only screen and (max-width: 768px) {
  .home-wrapper {
    .carousel-container {
      padding: 0 0.5em !important;

      .carousel-content {
        margin-top: 2.5em;
      }

      h1 {
        font-size: 5.25em;
        line-height: 118px;
      }

      h3 {
        font-size: 3em;
        line-height: 60px;
      }

      p {
        line-height: 38px;
        padding-bottom: 10px;
        padding-top: 12px;
        font-size: 1.3em;
      }

      .carousel-img {
        img {
          width: 383px;
          height: 479px;
          margin-top: 0em;
        }
      }

      .carousel-indicators {
        display: none;
      }

    }

    // .title-primary {
    //   h3 {
    //     padding: 33px 0 33px 0;
    //     font-size: 2em;
    //     line-height: 42px;
    //   }
    // }

    .mealSubscription-wrapper {
      h3 {
        padding: 33px 0 33px 0;
        margin-bottom: 0;
        font-size: 2em;
        line-height: 42px;
      }

      .mealSubscription-img {
        .nonVeg-wrapper {
          right: 0%;
          width: 419px;
          height: 465px;

          p {
            padding-left: 40px;
            padding-right: 40px;
            padding-top: 6px;
          }

          .btn-green {
            padding: 6px 18px;
            font-size: 1em;
          }
        }
      }
    }

    .finalCover-wrapper {
      .finalCover-img {
        .nonVeg-wrapper {
          width: 424px;

          p {
            padding-bottom: 4px;
            padding-left: 40px;
            padding-right: 40px;
            padding-top: 10px;
          }
        }
      }
    }

    .chefCrafted-wrapper {
      .chefCrafted-img {
        .chefCrafted-content {
          left: 34%;
          padding-top: 57px;
          width: 272px;

          .btn-white {
            padding: 10px 30px 10px 30px;
            font-size: 1em;
            line-height: 20px;
          }
        }
      }

      h3 {
        padding: 40px 0 40px 0;
        font-size: 2em;
        line-height: 42px;
      }
    }
  }
}

@media only screen and (min-width: 769px) and (max-width: 991px) {
  .home-wrapper {
    .carousel-container {
      padding: 0 1.5em !important;

      .carousel-content {
        margin-top: 2.5em;
      }

      h3 {
        font-size: 3em;
        line-height: 60px;
      }

      h1 {
        font-size: 4.25em;
        line-height: 90px;
      }

      p {
        font-size: 1.35em;
        line-height: 38px;
        padding-top: 10px;
      }

      .carousel-img img {
        width: 362px;
        height: 477px;
        margin-left: 0em;
      }

      .carousel-indicators {
        bottom: 3%;
      }
    }

    // .title-primary {
    //   h3 {
    //     padding: 33px 0 33px 0;
    //     font-size: 3em;
    //     line-height: 58px;
    //   }
    // }

    .mealSubscription-wrapper {
      h3 {
        padding: 33px 0 33px 0;
        font-size: 3em;
        line-height: 60px;
      }
    }

    .chefCrafted-wrapper {
      h3 {
        padding: 33px 0 33px 0;
        font-size: 3em;
        line-height: 60px;
      }

      .chefCrafted-img {
        margin-bottom: 70px;

        .chefCrafted-content {
          .btn-white {
            padding: 10px 32px 10px 32px;
            font-size: 1em;
            line-height: 20px;
          }
        }
      }
    }

    .finalCover-wrapper {
      margin-top: 30px;
    }

  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .home-wrapper {
    .carousel-container {
      padding: 0 1.5em !important;

      .carousel-content {
        margin-top: 4.5em;
      }

      .carousel-img {
        img {
          width: 478px;
          height: 595px;
          margin-left: 0em;
        }
      }

      p {
        font-size: 1.55em;
        line-height: 49px;
        padding-bottom: 95px;
        padding-top: 12px;
      }

      h3 {
        font-size: 3.2375em;
        line-height: 54px;
      }

      .carousel-indicators {
        bottom: 2%;
      }
    }

    // .title-primary {
    //   h3 {
    //     padding: 38px 0 38px 0;
    //     font-size: 3.2375em;
    //     line-height: 62px;
    //   }
    // }

    .mealSubscription-wrapper {
      h3 {
        padding: 33px 0 33px 0;
        font-size: 3.2375em;
        line-height: 61px;
      }
    }

    .chefCrafted-wrapper {
      h3 {
        padding: 50px 0 50px 0;
        font-size: 3.2375em;
        line-height: 50px;
      }

      .chefCrafted-img {
        margin-bottom: 88px;
      }
    }
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1880px) {
  .home-wrapper {
    .carousel-container {
      padding: 0 3.5em !important;

      .carousel-indicators {
        bottom: 0%;
      }

      .carousel-img img {
        width: 656px;
        height: 616px;
      }

      .carousel-content {
        margin-top: 6.5em;
      }
    }
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .home-wrapper {
    .carousel-container {
      padding: 0 1.5em !important;

      .carousel-indicators {
        bottom: 0%;
      }

      h3 {
        font-size: 3.2375em;
        line-height: 60px;
      }

      .carousel-img img {
        width: 605px;
        height: 600px;
      }

      .carousel-content {
        margin-top: 4.5em;
      }

      h1 {
        font-size: 6em;
        line-height: 102px;
      }

      p {
        font-size: 1.65em;
        line-height: 48px;
        padding-bottom: 70px;
        padding-top: 39px;
      }
    }

    .finalCover-wrapper {
      .finalCover-img {
        .nonVeg-wrapper {
          background: rgba(57, 52, 52, 0.5);
        }
      }
    }
  }

  .premiumMeals-wrapper {
    img {
      width: 260px !important;
      height: 246px !important;
    }

    // hr {
    //   border: 1px solid $grey2 !important;
    // }
  }
}