@import '../../assets/styles/variables';

.tastyMealsPage-wrapper {
    background-color: $greenLight;

    hr {
        border: 1px dashed $grey;
    }

    .presetPlan-wrapper {
        display: flex;
        justify-content: center;
        padding-top: 94px;

        h2 {
            font-size: 80px;
            line-height: 103px;
            font-family: $global-font3;
            text-align: center;
        }

        p {
            font-size: 28px;
            line-height: 50px;
            font-family: $global-font;
            text-align: center;
            padding-top: 30px;
            padding-bottom: 68px;

            span {
                font-size: 35px;
                line-height: 50px;
                color: $red2;
                font-family: $global-font;
            }
        }
    }
}