@import "./variables";

.breadcrumb-item+.breadcrumb-item::before {
    content: url('../icons/breadcrumps-arrow.svg') !important;
}

.btn-light {
    border-radius: 19px !important;
    border: 1px solid $grey !important;
    background-color: $white !important;
    color: $black2 !important;
    padding: 0 20px;
    border: none;
}

.btn-light.active{
    background-color: $red2 !important;
    border-color: $red2 !important;
    color: $grey6 !important;
}

.btn-red {
    background-color: $red2 !important;
    border-color: $red2 !important;
    color: $grey6 !important;
    border-radius: 30px !important;
    // padding: 0 25px;
    border: none;
}

.btn-primary {
    background-color: $red2;
    color: $grey6;
    border-radius: 30px;
    font-family: $global-font3;
    border: none !important;
    text-transform: uppercase;
}

.btn-green {
    background-color: $green;
    border-radius: 30px;
    color: $grey6;
    font-family: $global-font3;
    text-transform: uppercase;
    border: none !important;
}