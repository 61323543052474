@import '../../../assets/styles/variables';

.premiumMeals-wrapper {

    h3 {
        font-size: $fontsize8;
        line-height: 71px;
        color: $black;
        font-family: $global-font3;
    }

    h6 {
        font-size: $fontsize3;
        line-height: 26px;
        color: $black;
        font-family: $global-font3;
    }

    .premiumMeals-dflex {
        display: flex;
        justify-content: space-between;
    }

    a {
        font-size: $fontsize10;
        line-height: 35px;
        color: $orange;
        font-family: $global-font3;
        padding-top: 50px;
        text-decoration: none;
    }

    hr {
        border: 1px solid $grey2 !important;
        margin-bottom: 43px;
        margin-top: 27px;
    }

    img {
        width: 311px;
        height: 281px;
        border-radius: 20px;
        opacity: 1;
    }

    .dailyVeg {
        position: relative;

        h6 {
            font-size: $fontsize2;
            line-height: 21px;
            color: $grey4;
            font-family: $global-font3;
        }

        span {
            color: $red2;
            font-size: $fontsize3;
            line-height: 26px;
            font-family: $global-font3;
        }
    }

    .planStarts {
        position: absolute;
        top: 0;
        left: 15%;
        background: #191515;
        opacity: 0.83;
        border-radius: 0 0 10px 10px;
        padding: 7px;
    }

    h5 {
        font-size: $fontsize5;
        line-height: 31px;
        color: $black;
        font-family: $global-font3;
        padding-top: 18px;
    }

    p {
        font-size: $fontsize2;
        line-height: 21px;
        color: $black;
        font-family: $global-font3;
        font-weight: normal;
    }
}

// ---media query---


@media only screen and (max-width: 768px) {
    .premiumMeals-wrapper {
        h3 {
            font-size: 2em;
            line-height: 40px;
        }

        a {
            font-size: 1em;
            line-height: 24px;
        }

        img {
            margin-left: auto;
            margin-right: auto;
            display: block;
        }

        .planStarts {
            left: 20%;
        }
    }
}

@media only screen and (min-width: 769px) and (max-width: 991px) {

    .premiumMeals-wrapper {
        img {
            width: 169px;
            height: 176px;
        }

        .dailyVeg {
            h6 {
                font-size: 0.85em;
                line-height: 12px;
            }

            span {
                font-size: 1em;
                line-height: 16px;
            }
        }

        .planStarts {
            left: 17%;
            padding: 5px;
        }
        
        h3 {
            font-size: 3em;
            line-height: 60px;
        }
    }


}



@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .premiumMeals-wrapper img {
        width: 211px;
        height: 212px;
        border-radius: 20px;
        opacity: 1;
    }

    .premiumMeals-wrapper h3 {
        font-size: 3.2375em;
        line-height: 60px;
    }
}