@import '../../../assets/styles/variables';

.dietPlanOption-wrapper {
    .dietPlanOption {

        width: 257px;
        padding: 20px 36px;
        border: 1px solid $grey;
        border-radius: 16px;

        h5 {
            // font-size: 24px;
            font-size: $fontsize5;
            line-height: 20px;
            color: $green;
            font-family: $global-font3;
            text-align: center;
            padding-bottom: 13px;
        }

        h4 {
            font-size: 28px;
            line-height: 20px;
            font-family: $global-font3;
            color: $black;
            text-align: center;

            span {
                // font-size: 20px;
                font-size: $fontsize3;
                // line-height: 26px;
                line-height: 1.625em;
                color: $red2;
                font-family: $global-font3;
            }

            .suffix {
                // font-size: 16px;
                font-size: $fontsize2;
                line-height: 20px;
            }
        }

        p {
            // font-size: 14px;
            font-size: $fontsize1;
            line-height: 20px;
            color: $black;
            font-family: $global-font3;
            text-align: center;
            padding-top: 15px;
            padding-bottom: 5px;
        }

        button {
            // font-size: 16px;
            font-size: $fontsize2;
            line-height: 21px;
            color: $grey6;
            font-family: $global-font3;
            background-color: $red2;
            border-radius: 30px;
            margin-left: auto;
            margin-right: auto;
            display: block;
            margin-top: 16px;
            padding: 8px 38px;
        }
    }
}