@font-face {
    font-family: 'Futura PT Book';
    src: url('../fonts/FuturaPTBook.otf');
}

@font-face {
    font-family: 'Futura PT Light';
    src: url('../fonts/FuturaPTLight.otf');
}

@font-face {
    font-family: 'Futura PT Medium';
    src: url('../fonts/FuturaPTMedium.otf');
}

@font-face {
    font-family: 'Sacramento Regular';
    src: url('../fonts/Sacramento-Regular.ttf');
}

$primary: #0d6efd;
$secondary: #6c757d;
$danger: #dc3545;
$black: #000000;
$black2: #0B0B0B;
$black3: #070707;
$black4: #191515;
$black5: #393434;
$white: #FFFFFF;
$white2: #FFFEFF;
$grey: #707070;
$grey2: #B9B9B9;
$grey3: #AAAAAA;
$grey4: #EDEAEA;
$grey5: #BABABA;
$grey6: #F2EAEA;
$grey7: #D1D1D1;
$grey8: #CBC8C8;
$greenLight: #F2FCF8;
$green: #6C9D11;

$green2: #6C9D10;
$green3: #D3EDE4;
$green4: #E2F8F0;
$red: #E44300;
$red2: #F56F5D;
$orange: #F56D34;
$yellow: #F0BC5E;

$global-font: "Futura PT Book";
$global-font2: "Futura PT Light";
$global-font3: "Futura PT Medium";
$logo-font: 'Sacramento Regular';

$font-weight-light: 300;
$font-weight-light-medium: 400;
$font-weight-medium: 500;
$font-weight-bold: 600;

$fontsize1: 0.875em; //14px
$fontsize2: 1em; //16px 
$fontsize3: 1.25em; //20px
$fontsize4: 1.375em; //22px
$fontsize5: 1.5em; //24px
$fontsize6: 1.875em; //30px
$fontsize7: 3.125em; //50px
$fontsize8: 3.4375em; //55px
$fontsize9: 6.25em; //100px
$fontsize10: 1.75em; //28px
$fontsize11: 5em; //80px

$size1: 1.125em; //18px
$size2: 1.1875em; //19px
$size3: 1.3125em; //21px