@import '../../../assets/styles/variables';

.tastyMeals-wrapper {
    padding-top: 47px;

    h2 {
        text-align: center;
        font-size: $fontsize11;
        line-height: 103px;
        font-family: $global-font3;
        color: $black;
        display: flex;
        width: 85%;
    }

    p {
        text-align: center;
        font-size: $fontsize10;
        line-height: 50px;
        color: $black;
        font-family: $global-font3;
    }

    .btn-green {
        padding: 18px 25px 18px 25px;
        margin-left: auto;
        margin-right: auto;
        display: block;
        font-size: $fontsize3;
        line-height: 26px;
        margin-top: 20px;
    }
}

@media only screen and (max-width: 768px) {
    .tastyMeals-wrapper {
        h2 {
            font-size: 3em;
            width: 90%;
        }

        p {
            font-size: 1.2em;
            line-height: 33px;
        }

        .btn-green {
            padding: 8px 20px;
            font-size: 1em;
            line-height: 23px;
            margin-bottom: 32px;
        }
    }
}

@media only screen and (min-width: 769px) and (max-width: 991px) {
    .tastyMeals-wrapper {
        h2 {
            font-size: 3em;
            line-height: 86px;
            width: 100%;
        }

        .btn-green {
            font-size: 1em;
            line-height: 20px;
            margin-bottom: 30px;
        }
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .tastyMeals-wrapper {
        h2 {
            font-size: 4em;
            line-height: 80px;
            width: 88%;
        }
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .tastyMeals-wrapper {
        h2 {
            font-size: 4em;
        }
    }
}